import React, { useCallback, useEffect, useState } from "react";
import MyAppCard from "../card/MyAppCard";
import MyAppsEmpty from "./MyAppsEmpty";
import useGetApiRequests from "../../../../services/axios/useApiRequests";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../../../layouts/component/Loader";

import { retrieveData } from "../../../../services/storage/Storage";
import { debounce } from "lodash";
import { handleRequestError } from "../../../../layouts/toast/ErrorNotificationMessage";
import { setMyAppsCount } from "../../../../redux/slice/app-store/AppDetailsSlice";
import { clearAllEditData } from "../../../../redux/slice/app-store/appConfigureSlice";
const MyApps = () => {
  const [myAppDetails, setMyAppDetails] = useState<any>([]);
  const [provisioningLists, setProvisioningLists] = useState<any>([]);
  const [loader, setLoader] = useState<any>(false);
  const [myAppsList, setMyAppsList] = useState<any>([]);

  const reInitiateApps = useSelector((state: any) => state?.appsRenderSlice?.appRender);
  const searchValue = useSelector((state: any) => state?.appSearchSlice?.searchApps?.[0]);
  const reloadAppStore = useSelector((state: any) => state?.provisioning.reloadAppStore);
  const provisioning = useSelector((state: any) => state?.provisioning);

  const realmId = retrieveData("realmId", true);
  const dispatch = useDispatch();
  const myAppList = useGetApiRequests("myAppsList", "GET");

  const provisioningAppList = useGetApiRequests("provisioningAppsList", "GET");

  const searchMyApps = (searchApp: any) => {
    if (!searchApp?.name || searchApp?.name?.trim() === "") {
      // If search input is empty, reset to the original list
      setMyAppDetails(myAppsList);
      return;
    }

    const searchTerm = searchApp?.name.toLowerCase();
    const filteredApps = myAppsList?.filter((app: any) => {
      const appName = app?.akkuMasterClientDto?.name?.toLowerCase();
      return appName?.includes(searchTerm);
    });
    setMyAppDetails(filteredApps);
  };

  // Debounced API call function
  const debouncedSearchMyApps = useCallback(
    debounce((searchPayload) => {
      searchMyApps(searchPayload);
    }, 100),
    [searchValue?.name],
  );
  useEffect(() => {
    const searchApp = searchValue;
    if (searchApp?.name && searchApp?.name !== "") {
      debouncedSearchMyApps(searchApp);
    } else {
      getMyAppList();
    }
    dispatch(clearAllEditData());
  }, [reInitiateApps, searchValue, reloadAppStore]);

  const sortMyAppsDetails = (items: any) => {
    const sortedArrays = items?.sort((a: any, b: any) => {
      const firstElements = a?.akkuMasterClientDto?.name?.toUpperCase();
      const secondElements = b?.akkuMasterClientDto?.name?.toUpperCase();
      if (firstElements < secondElements) {
        return -1;
      }
      if (firstElements > secondElements) {
        return 1;
      }

      // names must be equal
      return 0;
    });
    return sortedArrays;
  };

  const getProvisioningAppList = async (searchValue?: any) => {
    setLoader(true);
    let params = {
      realmId: realmId,
    };
    let queryParams: any;
    if (searchValue && queryParams !== "") {
      queryParams = {
        searchSpName: searchValue?.name,
      };
    } else {
      queryParams = {};
    }

    try {
      const response: any = await provisioningAppList("", queryParams, params);
      const status = response.status;

      if (status === 200) {
        const sortAppDetails: any = sortMyAppsDetails(response?.data?.data);
        // dispatch(setProvisionAppsLength(sortAppDetails?.length));
        setProvisioningLists(sortAppDetails);
      }
      setTimeout(() => {
        setLoader(false);
      }, 1000);
    } catch (err: any) {
      setLoader(false);
      const listNotFound = err?.response?.data?.message?.includes("provider list not found");
      if (!listNotFound) {
        handleRequestError(err);
      }
    }
  };

  const getMyAppList = async () => {
    setLoader(true);
    let params = {
      realmId: realmId,
    };
    try {
      const response: any = await myAppList("", params);
      const status = response.status;
      if (status === 200) {
        const myAppDetails: any = sortMyAppsDetails(response?.data?.data);
        dispatch(setMyAppsCount(myAppDetails?.length));
        setMyAppDetails(myAppDetails || []);
        setMyAppsList(response?.data?.data);
        // dispatch(setNonProvisionAppsLength(myAppDetails?.length));
      }
      setLoader(false);
    } catch (err: any) {
      setLoader(false);
    }
  };
  return (
    <>
      {loader && <Loader />}
      <div data-testid="my-apps-empty" className="pt-6 flex flex-wrap">
        {myAppDetails?.length !== 0 ? (
          <MyAppCard myAppDetails={myAppDetails} provisioningLists={provisioningLists} getProvisioningAppList={getProvisioningAppList} available={false} getMyAppList={getMyAppList} />
        ) : (
          <MyAppsEmpty />
        )}
      </div>
    </>
  );
};

export default MyApps;
