import { Button, Modal } from "antd";
import React, { useEffect, useState } from "react";
import { images } from "../../../../../assets/images/ImagePaths";
import CustomButtonBack from "../../../../../layouts/component/CustomButtonBack";
import ImageView from "../../../../../layouts/component/ImageView";
import "./NewAppConfiguration.scss";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setConnectorId, setEditStatusToggle, setGeneralDetails, setGeneralDetailsEdit, setIsDuplicate } from "../../../../../redux/slice/provisioning/ProvisioningSlice";
import { clearAllEditData, setIsConfigureButtonPressed } from "../../../../../redux/slice/app-store/appConfigureSlice";
import { storeData } from "../../../../../services/storage/Storage";
import { setAppConfigDetails } from "../../../../../redux/slice/app-store/AppDetailsSlice";
const NewAppConfiguration = (props: any) => {
  const appConfigure = useSelector((state: any) => state?.appConfigure);
  const generalDetails = useSelector((state: any) => state?.provisioning?.generalDetails);
  const appDetails = useSelector((state: any) => state?.AppDetailsSlice?.appDetails);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { openPopUp, handleModalClose, hasEditAppStorePermission, hasCreateAppStorePermission, setPermissionsModal, provisionObject } = props;
  const [open, isOpen] = useState<boolean>(false);
  const [isChecked, setIsChecked] = useState<boolean>(false);
  const [adChecked, setAdChecked] = useState<boolean>(false);
  const [typesName, setTypesName] = useState<string>("");
  const [isActiveProvisioningError, setIsActiveProvisioningError] = useState<boolean>(false);
  const [showSSOError, setShowSSOError] = useState<boolean>(false);
  const [hasPermission, setHasPermission] = useState<string>("");
  useEffect(() => {
    isOpen(openPopUp);
    if (appConfigure?.isConfigureButtonPressed) {
      setHasPermission("create");
    } else if (!appConfigure?.isConfigureButtonPressed) {
      setHasPermission("edit");
    }
  }, [openPopUp]);

  const handleCloseModal = () => {
    handleModalClose();
    dispatch(setIsConfigureButtonPressed(false));
    dispatch(clearAllEditData());
    setShowSSOError(false);
    setIsActiveProvisioningError(false);
    setAdChecked(false);
    setTypesName("");
    setIsChecked(false);
  };

  const handleNavigate = () => {
    if (typesName === "sso") {
      if ((hasEditAppStorePermission && hasPermission === "edit") || (hasCreateAppStorePermission && hasPermission === "create")) {
        // ConfigureSingleApp();
        if (!appConfigure?.isConfigureButtonPressed) {
          dispatch(setAppConfigDetails(provisionObject?.akkuClientDto));
          storeData("configureApp", "edit", true);
        } else {
          // dispatch(setAppConfigDetails(provisionObject?.akkuMasterClientDto));
          storeData("configureApp", "configure", true);
        }

        dispatch(setIsConfigureButtonPressed(false));
        navigate("/app-store/configure-app");
      } else {
        setPermissionsModal(true);
      }
    } else {
      if (appConfigure?.isConfigureButtonPressed) {
        dispatch(
          setGeneralDetails({
            ...generalDetails,
            name: appDetails?.name,
          }),
        );
        navigate("/account-provision");
      } else {
        dispatch(setEditStatusToggle(true));
        dispatch(setConnectorId(provisionObject?.akkuProvisioningConnectorDto?.akkuProvisioningConnectorId));
        dispatch(setGeneralDetailsEdit(provisionObject?.akkuProvisioningConnectorDto));
        dispatch(setIsDuplicate(false));
        // dispatch(setEditStatusToggle(false));
        navigate("/account-provision");
      }
    }
    // Handle navigation logic here
  };
  const handleCheckboxChange = (e: any, type: string) => {
    setTypesName(type);
    if (type === "provisioning") {
      if (appConfigure.provisioningEditStatus || appConfigure?.provisioningConfigEdit?.isActiveConfig) {
        setIsActiveProvisioningError(true);
      } else {
        setIsActiveProvisioningError(false);
      }
      setShowSSOError(false);
    } else {
      if (appConfigure?.SSOConfigEdit?.isActiveConfig) {
        setShowSSOError(true);
      } else {
        setShowSSOError(false);
      }
      setIsActiveProvisioningError(false);
    }
    setAdChecked(false);
  };
  const shouldDisableButton = () => {
    if (typesName === "provisioning") {
      return appConfigure.provisioningEditStatus || appConfigure?.provisioningConfigEdit?.isActiveConfig;
    } else if (typesName === "sso") {
      return appConfigure?.SSOConfigEdit?.isActiveConfig;
    }
    return true;
  };
  return (
    <Modal className="new-app rounded-lg" maskClosable={false} centered open={open} footer={false} onCancel={handleCloseModal}>
      <div className={`modal-body  py-4 ${isActiveProvisioningError ? "errorStyle" : ""} ${showSSOError ? "errorStyle" : ""}`}>
        <p className="title font-Inter">Which one do you like to {appConfigure?.isConfigureButtonPressed ? "configure" : "edit"}?</p>
        <div className="flex w-full justify-center items-center mt-8 mb-5 ">
          <div className={`${typesName === "sso" ? "isActive" : ""} w-1/2  img-container flex flex-col`}>
            <input type="checkbox" checked={isChecked} onChange={(e) => handleCheckboxChange(e, "sso")} className="block z-20 w-full h-full absolute opacity-0 cursor-pointer top-0 left-0" />
            <ImageView src={images.provisionIcon} alt="notificationDone" className="delete-apps z-10" />
            <p>SSO</p>
          </div>
          <div className={`${typesName === "provisioning" ? "isActive" : ""} w-1/2 flex flex-col img-container `}>
            <input
              type="checkbox"
              disabled
              checked={adChecked}
              onChange={(e) => handleCheckboxChange(e, "provisioning")}
              className="block- z-20 w-full h-full absolute opacity-0 cursor-pointer top-0 left-0"
            />
            <ImageView src={images.ssoIcon} alt="notificationDone" className="delete-apps z-10" />
            <p>Provisioning</p>
            <small className="text-[15px] font-Inter text-[#747577]">Coming soon...</small>
          </div>
        </div>
        <div className="flex  justify-center items-center gap-3 warning-message">
          <ImageView src={images?.warningDanger} alt="warningIcon" className="w-20 h-20 warning-message" />
          {showSSOError && appConfigure?.SSOConfigEdit?.isActiveConfig && <span className="text-[#C31717] font-Inter text-[20px] font-medium">Please configure SSO.</span>}
          {appConfigure?.provisioningConfigEdit?.isActiveConfig && isActiveProvisioningError && (
            <span className="text-[#C31717] font-Inter text-[20px] font-medium">Please configure provisioning.</span>
          )}
          {appConfigure?.provisioningEditStatus && isActiveProvisioningError && (
            <span className="text-[#C31717] font-Inter text-[20px] font-medium">Please deactivate Provisioning to continue edit.</span>
          )}
        </div>
      </div>
      <div className="modal-footer py-6">
        <div className="w-full flex justify-center">
          <CustomButtonBack onClick={handleCloseModal} text={"Cancel"} />
          <Button onClick={handleNavigate} className="next-btn  bg-[#5441DA]  lg:h-[56px] md:h-[48px] text-white" size="large" disabled={shouldDisableButton()}>
            {appConfigure?.isConfigureButtonPressed ? "Configure" : "Next"}
          </Button>
        </div>
      </div>
    </Modal>
  );
};
export default NewAppConfiguration;
