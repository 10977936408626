import React, { useState } from "react";
import { getPermissionStyle, hasPermission } from "../../../../../redux/slice/permissions/permissionUtils";
import { modulePermissions } from "../../../../../const/RolePermissions";
import { useDispatch, useSelector } from "react-redux";
import ProvisioningStatusToggle from "../status-toggle/provisioning-status-toggle/ProvisioningStatusToggle";
import ActivateProvisionModal from "../modal/ActivateProvisionModal";
import useGetApiRequests from "../../../../../services/axios/useApiRequests";
import { handleRequestError } from "../../../../../layouts/toast/ErrorNotificationMessage";
import DeActivateProvisionSuccessModal from "../modal/DeActivateProvisionSuccessModal";
import ProvisionEditStatusModal from "../modal/ProvisionEditStatusModal";
import { provisioningMessage } from "../../../const/appStoreConstMessage";
import PermissionsModal from "../../../../../layouts/permissionsModal/PermissionsModal";
import { cursorNotAllowedStyle } from "../../../../../hooks/hooks";
import { useNavigate } from "react-router-dom";
import { setAppConfigDetails } from "../../../../../redux/slice/app-store/AppDetailsSlice";
import { setAppRender } from "../../../../../redux/slice/appsRenderSlice";
import { Tooltip } from "antd";
export default function ProvisioningCardOption({ item, index }: any) {
  const permissions = useSelector((state: any) => state?.permissionsSlice?.attributes);

  const appDetails = useSelector((state: any) => state?.AppDetailsSlice?.appDetails);
  const hasCreateAppStorePermission = permissions && hasPermission(permissions, modulePermissions.appStore, "create");
  const hasEditAppStorePermission = permissions && hasPermission(permissions, modulePermissions.appStore, "update");

  const activateDeactivateConnector = useGetApiRequests("provisioningConnector", "PUT");
  const provisioningConnectorDetails = useGetApiRequests("provisioningConnectorDetails", "GET");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const generalDetails = useSelector((state: any) => state?.provisioning);
  const reInitiateApps = useSelector((state: any) => state?.appsRenderSlice?.appRender);

  const [activateProvisionModal, setActivateProvisionModal] = useState<boolean>(false);
  const [deActivateSuccessModalOpen, setDeActivateSuccessModalOpen] = useState<boolean>(false);
  const [deActivateSuccessMessage, setDeActivateSuccessMessage] = useState<string>("");
  const [activeAccess, setActiveAccess] = useState<boolean>(false);
  const [permissionModal, setPermissionModal] = useState(false);

  const handleCloseActivateProvisionModal = () => {
    setActivateProvisionModal(false);
  };

  const activateConnectorFunction = () => {
    createActivateDeactivatePayload(item);
    handleCloseActivateProvisionModal();
  };

  const getProvisioningConnectorDetails = async (item: any) => {
    const akkuProvisioningConnectorId = item?.akkuProvisioningConnectorDto?.akkuProvisioningConnectorId;
    const params = { akkuProvisioningConnectorId };
    try {
      const response: any = await provisioningConnectorDetails("", "", params);
      const status = response?.status;
      if (status === 200) {
        const data = response?.data?.data;
        const provisioningConfigureStatus: boolean = await extractKeys(data);
        if (provisioningConfigureStatus) {
          setActivateProvisionModal(true);
        } else {
          setActiveAccess(true);
        }
      }
    } catch (err: any) {
      handleRequestError(err);
      console.error("err:", err);
    }
  };

  const hasNonEmptyValue = (data: any) => {
    return data?.some((item: any) => {
      if (!item) {
        return false;
      }

      return Object.values(item).some((value: any) => value && value.trim() !== "");
    });
  };

  const extractKeys = async (data: any) => {
    const result: any[] = [];

    data?.endPointResponseDtos?.forEach((endpoint: any) => {
      const isEmptyObject = Object.keys(endpoint.attributeMappings).length === 0;
      if (!isEmptyObject) {
        result.push(endpoint.attributeMappings);
      }
    });
    const nonEmptyValue = await hasNonEmptyValue(result);

    return nonEmptyValue;
  };

  const createActivateDeactivatePayload = async (item: any) => {
    const payload = { ...item.akkuProvisioningConnectorDto };
    payload.isActive = !item?.akkuProvisioningConnectorDto?.isActive;
    const pathParams: any = {
      id: item?.akkuProvisioningConnectorDto?.akkuProvisioningConnectorId,
    };
    try {
      const response: any = await activateDeactivateConnector(payload, "", pathParams);
      const status = response.status;
      if (status === 200) {
        setDeActivateSuccessModalOpen(true);
        const activeStatus = item?.akkuProvisioningConnectorDto?.isActive;
        setDeActivateSuccessMessage(activeStatus ? "Provisioning engine deactivated successfully." : "Provisioning engine activated successfully.");
        setTimeout(() => {
          handleCloseDeActivateSuccessModal();
          dispatch(setAppRender(!reInitiateApps));
          // dispatch(setReloadAppStore(true));
        }, 3000);
      }
    } catch (err) {
      handleRequestError(err);
    }
  };
  const handleCloseDeActivateSuccessModal = () => {
    setDeActivateSuccessModalOpen(false);
  };

  const handleActiveProvisioning = () => {
    if (hasEditAppStorePermission) {
      getProvisioningConnectorDetails(item);
    } else {
      setPermissionModal(true);
    }
  };
  const handleDeActiveProvisioning = () => {
    if (hasEditAppStorePermission) {
      createActivateDeactivatePayload(item);
    } else {
      setPermissionModal(true);
    }
  };

  const handleCloseActiveAccessModal = () => {
    setActiveAccess(false);
  };
  const handleClosePermissionModal = () => {
    setPermissionModal(false);
  };

  const redirectProvisioning = (item: any) => {
    if (hasCreateAppStorePermission) {
      dispatch(setAppConfigDetails(item));
      navigate("/account-provision");
    } else {
      setPermissionModal(true);
    }
  };
  return (
    <>
      <Tooltip title={"Coming soon..."}>
        <p className={`app-configure-btn font-Inter`} role="none">
          Configure
        </p>
      </Tooltip>
      {/* {item.akkuProvisioningConnectorDto ? (
        <ProvisioningStatusToggle
          provisioningStatus={item?.akkuProvisioningConnectorDto?.isActive}
          handleDeActiveProvisioning={handleDeActiveProvisioning}
          handleActiveProvisioning={handleActiveProvisioning}
        />
      ) : (
          <>
            <p className={`app-configure-btn font-Inter text-[#fff] ${cursorNotAllowedStyle(hasCreateAppStorePermission)}`} onClick={() => redirectProvisioning(item?.akkuMasterClientDto)} role="none">
              Configure
            </p>
          </>
      )} */}

      {deActivateSuccessModalOpen && (
        <DeActivateProvisionSuccessModal open={deActivateSuccessModalOpen} handleModalClose={handleCloseDeActivateSuccessModal} responseMessage={deActivateSuccessMessage} />
      )}

      {activeAccess && <ProvisionEditStatusModal open={activeAccess} handleModalClose={handleCloseActiveAccessModal} message={provisioningMessage?.requestActivateProvisioning} />}

      {activateProvisionModal && <ActivateProvisionModal open={activateProvisionModal} handleClose={handleCloseActivateProvisionModal} activateConnectorFunction={activateConnectorFunction} />}
      {permissionModal && <PermissionsModal open={permissionModal} close={handleClosePermissionModal} />}
    </>
  );
}
