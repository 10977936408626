import React, { useRef, useState } from "react";
import useGetApiRequests from "../../../../../../../../services/axios/useApiRequests";
import * as Yup from "yup";
import { retrieveData } from "../../../../../../../../services/storage/Storage";
import { triggerNotification } from "../../../../../../../../layouts/toast/ToastBar";
import { Form, Formik } from "formik";
import { Button } from "antd";
import CustomButtonBack from "../../../../../../../../layouts/component/CustomButtonBack";
import Loader from "../../../../../../../../layouts/component/Loader";
import MobileNotification from "../MobileNotification";
import { useDispatch, useSelector } from "react-redux";
import { setUpdateDashboard } from "../../../../../../../../redux/slice/Users/UserDashboardUpdateSlice";
import UserCreationNotificationModal from "./UserCreationNotificationModal";
import CustomFieldArray from "../custom-field/CustomFieldArray";
import CustumFieldModal from "../custom-field/CustumFieldModal";
import { handleRequestError } from "../../../../../../../../layouts/toast/ErrorNotificationMessage";
const PersonalDetailsForm = (props: any) => {
  const newUserRequest = useGetApiRequests("singleUsers", "POST");
  const toggleTable = useSelector((state: any) => state?.UserDashboardUpdateSlice?.updateDashboard);
  const customerDetailsDefaultPassword = useSelector((state: any) => state?.userBasicDetails?.customerDetailsDefaultPassword); // if is true,it is grt,or else it is not
  const dispatch = useDispatch();
  const { initialValues, setInitialValues, handleCloseModal } = props;
  const selectedAssetType: string = "Both";
  const [loader, setLoader] = useState<boolean>(false);
  const [notification, setNotification] = useState<boolean>(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [openModal, setOpenModal] = useState(false);

  const [responseMessage, setResponseMessage] = useState<any>("");
  const realmId = retrieveData("realmId", true);
  const getValidationSchema = (assetType: any) => {
    let schemaFields: any = {
      customField: Yup.array().of(
        Yup.object().shape({
          newField: Yup.string()
            .test("is-not-first", "Please fill this field", function (value, context: any) {
              const index = context?.options?.index;
              if (index === 0) return true;
              return value !== undefined && value !== "";
            })
            .min(3, "Please enter at least 3 characters"),

          fieldLabel: Yup.string()
            .test("unique-check", "Attribute name already  exists", function (value: any) {
              const existingNames = ["telephoneNumber", "personal_email_id"];
              return !existingNames.includes(value);
            })
            .required("Field is required")
            .min(3, "Please enter at least 3 characters")
            .test("unique-name", "Field already exists", function (value: any) {
              const { path, options }: any = this;
              const fields: any = options.context.customField;
              const currentFieldIndex = parseInt(path.match(/\d+/)[0]);
              const isDuplicate = fields
                ?.filter((field: any, index: any) => index !== currentFieldIndex)
                .some((field: any) => {
                  return field.fieldLabel === value;
                });

              return !isDuplicate;
            }),
        }),
      ),
    };
    if (assetType === "Mobile Number" || assetType === "Both") {
      if (customerDetailsDefaultPassword) {
        schemaFields.phone = Yup.string().min(8, "Mobile Number must be at least 8 characters").max(13, "Mobile number cannot exceed 13 characters");
      } else {
        schemaFields.phone = Yup.string().required("Mobile Number is required").min(8, "Mobile Number must be at least 8 characters").max(13, "Mobile number cannot exceed 13 characters");
      }
    }
    if (assetType === "Personal Email" || assetType === "Both") {
      if (customerDetailsDefaultPassword) {
        schemaFields.verifyEmail = Yup.string().email("Invalid email");
      } else {
        schemaFields.verifyEmail = Yup.string().email("Invalid email").required("Email is required");
      }
    }
    return Yup.object().shape(schemaFields);
  };
  const onSubmit = (values: any) => {
    if (selectedAssetType === "Mobile Number") {
      setInitialValues({
        ...initialValues,
        phone: values.phone,
      });
    } else {
      setInitialValues({
        ...initialValues,
        verifyEmail: values.verifyEmail,
      });
    }
    handleCreateSingleUser(values);
  };

  const handleCreateSingleUser = async (values: any) => {
    const convertedObject = values.customField.reduce((acc: any, curr: any) => {
      const key = curr.fieldLabel.trim().replace(/\*$/, "");
      if (!acc[key]) {
        acc[key] = [];
      }
      acc[key].push(curr.newField);

      return acc;
    }, {});

    setLoader(true);
    const realmName = retrieveData("realmName", true);
    const attributes: any = { ...convertedObject };
    if (values.phone) {
      attributes.telephoneNumber = [values.phone];
    }
    if (values.verifyEmail) {
      attributes.personal_email_id = [values.verifyEmail];
    }
    let payload: any = {
      realmName: realmName,
      realmId: realmId,
      userEntityKeyValueDtoList: [
        {
          username: initialValues.email,
          firstName: initialValues.firstName,
          lastName: initialValues.lastName,
          email: initialValues.email,
          emailVerified: true,
          enabled: true,
        },
      ],
      userAttributeKvDto: {
        attributes: attributes,
      },
    };
    if (initialValues?.userType !== "User") {
      const idList = [];
      idList.push(initialValues?.userType);
      payload.roleIdList = idList;
    }
    try {
      const response: any = await newUserRequest(payload); // Make the API request
      const status = response.status;
      if (status === 200) {
        setLoader(false);
        triggerNotification("success", response?.data?.message, "", "topRight");
        setNotification(true);
        dispatch(setUpdateDashboard(!toggleTable));
        if (customerDetailsDefaultPassword) {
          handleCloseModal();
        }
      }
    } catch (err: any) {
      setLoader(false);
      if (err?.response?.status === 401) {
        handleRequestError(err);
      } else {
        setModalOpen(true);
        setResponseMessage(err?.response?.data?.message);
      }
    }
  };
  const handleToBack = () => {
    handleCloseModal();
  };
  const handleClose = () => {
    handleCloseModal();
    setNotification(false);
  };

  type PushFunction = (obj: { newField: string; fieldLabel: string }) => void;
  type RemoveFunction = (index: number) => void;
  const pushRef = useRef<PushFunction | null>(null);
  const removeRef = useRef<RemoveFunction | null>(null);

  const handleExternalPush = (val: any) => {
    if (pushRef.current) {
      pushRef.current({ newField: "", fieldLabel: ` ${val} *` });
    }
  };

  const handleAddModal = () => {
    setOpenModal(false);
  };

  return (
    <>
      {loader && <Loader />}
      <Formik initialValues={initialValues} values={initialValues} validationSchema={getValidationSchema(selectedAssetType)} onSubmit={onSubmit} enableReinitialize={false}>
        {({ handleSubmit, setFieldValue, errors, touched, setErrors, values }) => {
          return (
            <Form onSubmit={handleSubmit}>
              <div className="w-full flex pt-[54px] flex-col personal-details">
                <div className="w-[80%] flex mx-auto justify-center flex-wrap">
                  <CustomFieldArray values={values} pushRef={pushRef} removeRef={removeRef} setFieldValue={setFieldValue} errors={errors} />
                </div>
                <div className={` bg-[#F5F5FA] modal-footer mt-[5rem] h-[100px] flex items-center absolute bottom-0 pr-[30px] w-[100%] mx-auto`}>
                  <div className="w-full flex justify-end">
                    <CustomButtonBack onClick={handleToBack} text={"Cancel"} />
                    <Button type="primary" htmlType="submit" className="flex items-center justify-evenly font-semibold text-[20px] font-Inter w-[187px] h-[56px] bg-[#5441DA] text-white">
                      Save
                    </Button>
                  </div>
                </div>
              </div>
            </Form>
          );
        }}
      </Formik>
      {openModal && <CustumFieldModal openModal={openModal} handleAddModal={handleAddModal} handleExternalPush={handleExternalPush} />}
      {notification && !customerDetailsDefaultPassword && <MobileNotification notification={notification} handleClose={handleClose} />}
      {modalOpen && <UserCreationNotificationModal type={"personalDetails"} modalOpen={modalOpen} setModalOpen={setModalOpen} responseMessage={responseMessage} closeAllModal={handleClose} />}
    </>
  );
};
export default PersonalDetailsForm;
