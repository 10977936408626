import React, { useEffect, useState } from "react";
import BasicDetails from "./basic-details/BasicDetails";
import { Button, Switch } from "antd";
import { retrieveData } from "../../../../services/storage/Storage";
import useGetApiRequests from "../../../../services/axios/useApiRequests";
import { handleRequestError } from "../../../../layouts/toast/ErrorNotificationMessage";
import { triggerNotification } from "../../../../layouts/toast/ToastBar";
import { ErrorMessages } from "../../../../const/Messages";
import ReactiveUserModal from "../user-dashboard/adduser/usertable/ReactiveUserModal";
import InActiveUserModal from "./basic-details/DeactivateUserModal";
import { useNavigate } from "react-router-dom";
import Loader from "../../../../layouts/component/Loader";
import "./editUserManagement.scss";
import ImageView from "../../../../layouts/component/ImageView";
import { images } from "../../../../assets/images/ImagePaths";
import ResetPasswordModal from "./basic-details/Modal/ResetPasswordModal";
import ResetPasswordHistoryModal from "./basic-details/Modal/ResetPasswordHistoryModal";
import { useSelector } from "react-redux";

export default function EditUserManagement() {
  interface TemporaryPasswordEvent {
    lastUpdatedOn: string;
    email: string;
    key: number;
  }
  const editUserStatus = useGetApiRequests("editUser", "PUT");
  const getTemporaryPasswordEvents = useGetApiRequests("getUserProfile", "GET");
  const currentSelectedRow = retrieveData("currentSelectedRow", true);
  const customerDetailsDefaultPassword = useSelector((state: any) => state?.userBasicDetails?.customerDetailsDefaultPassword);

  const [userEnabled, setUserEnabled] = useState(false);
  const [activeUser, setActivateUser] = useState(false);
  const [deActiveUser, setDeActivateUser] = useState(false);
  const [disableToggle, setDisableToggle] = useState(false);
  const [loader, setLoader] = useState(false);
  const [enable, setEnable] = useState(false);
  const [temporaryPasswordEvents, setTemporaryPasswordEvents] = useState<TemporaryPasswordEvent[]>([]);
  const [resetPasswordModalOpen, setResetPasswordModalOpen] = useState(false);
  const [resetPasswordHistoryModalOpen, setResetPasswordHistoryModalOpen] = useState(false);
  const [reloadPasswordEventsAPI, setReloadPasswordEventsAPI] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    getTemporaryPasswordEventsList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentSelectedRow?.key, reloadPasswordEventsAPI]);

  const onChangeUserStatus = (checked: boolean) => {
    setUserEnabled(checked);
    if (checked === true) {
      setActivateUser(true);
    } else {
      setDeActivateUser(true);
    }
  };

  const getTemporaryPasswordEventsList = async () => {
    setLoader(true);
    const pathParams = {
      id: `${currentSelectedRow?.key}/temporary-password-event`,
    };

    try {
      setActivateUser(false);
      setDeActivateUser(false);
      const response: any = await getTemporaryPasswordEvents("", {}, pathParams);
      const status = response.status;

      if (status === 200) {
        const responseData = response?.data?.data;
        const reformedPasswordEvents = responseData?.map((item: any, index: number) => ({
          lastUpdatedOn: item?.eventTime,
          email: `${item?.firstName} ${item?.lastName} (${item?.email})`,
          key: index + 1,
        }));

        setTemporaryPasswordEvents(reformedPasswordEvents ?? []);
      }
      setLoader(false);
    } catch (err) {
      setLoader(false);
      handleRequestError(err);
    }
  };

  const editUserStatusFunction = async () => {
    setLoader(true);
    const pathParams = {
      id: `${currentSelectedRow?.key}/status`,
    };
    const queryParams: object = {
      isEnabled: userEnabled,
      realmId: retrieveData("realmId", true),
    };
    try {
      setActivateUser(false);
      setDeActivateUser(false);
      const response: any = await editUserStatus("", queryParams, pathParams);
      const status = response.status;
      if (status === 200) {
        setLoader(false);
        const responseData = response?.data;
        triggerNotification("success", "", responseData?.message, "topRight");
        setEnable(true);
      }
    } catch (err) {
      setLoader(false);
      handleRequestError(err);
    }
  };

  const handleActivateUser = () => {
    editUserStatusFunction();
  };

  const handleCancelActivateUser = () => {
    setUserEnabled(!userEnabled);
    setActivateUser(false);
  };
  const handleCloseInActiveModal = () => {
    setUserEnabled(!userEnabled);
    setDeActivateUser(false);
  };
  const handleNavigate = () => {
    navigate("/user");
  };
  const handleCloseLoader = () => {
    setLoader(false);
  };
  const handleResetPasswordModal = () => {
    setResetPasswordModalOpen(true);
  };
  const handleCloseResetPasswordModal = () => {
    setResetPasswordModalOpen(false);
  };
  const handleCloseResetPasswordHistoryModal = () => {
    setResetPasswordHistoryModalOpen(false);
  };
  const handleResetPasswordHistoryModal = () => {
    setResetPasswordHistoryModalOpen(true);
  };
  return (
    <div className="akku-container">
      <div className="main-container editUser">
        <div className="dashboard-container _set-height rounded !p-0">
          <div className="bg-[#F1EFFF] p-4 rounded">
            <div className="flex justify-end items-center">
              <p className={`${userEnabled ? "  text-[#ABABAB]" : "text-[#111]"} pr-3  lg:text-[20px] text-[16px] font-Inter font-medium`}>Disable</p>
              <Switch disabled={disableToggle} data-testid="subscription-switch" checked={userEnabled} onChange={onChangeUserStatus} />
              <p className={`${userEnabled ? "  text-[#111]" : "text-[#ABABAB]"} pl-3  lg:text-[20px] text-[16px] font-Inter font-medium`}>Enable</p>
            </div>
          </div>

          <div className="dashboard-container">
            <div className="flex justify-between">
              <div>
                <div className="editUser-path mb-2 flex">
                  <p role="none" onClick={handleNavigate} className=" cursor-pointer">
                    User management /
                  </p>{" "}
                  <span>Edit Information</span>
                </div>
                <h1 className="editUser-title mb-6">Basic Details</h1>
              </div>

              <div className="w-1/2 flex justify-end items-start">
                <div className="flex items-end flex-col justify-end ">
                  {customerDetailsDefaultPassword && (
                    <>
                      <Button
                        type="primary"
                        className=" ml-4  flex items-center justify-evenly font-semibold text-[18px] font-Inter w-[187px] h-[44px] bg-[#5441DA] text-white rounded"
                        size="large"
                        onClick={handleResetPasswordModal}
                      >
                        Reset Password
                      </Button>

                      <div className="flex justify-center items-center gap-1 mt-2 cursor-pointer" role="none" onClick={handleResetPasswordHistoryModal}>
                        {temporaryPasswordEvents && temporaryPasswordEvents.length > 0 && (
                          <>
                            <ImageView src={images?.clock} alt="chevronDown" />
                            <span className="text-[#737373] text-[16px] font-normal font-Inter ">Last updated on : {temporaryPasswordEvents?.[0]?.lastUpdatedOn}</span>
                          </>
                        )}
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>

            <div className="basicDetails mt-3" data-testid="basic-details-component">
              <BasicDetails setUserEnabled={setUserEnabled} setDisableToggle={setDisableToggle} enable={enable} handleCloseLoader={handleCloseLoader} />
            </div>
          </div>
        </div>
      </div>
      {resetPasswordModalOpen && (
        <ResetPasswordModal
          modalOpen={resetPasswordModalOpen}
          handleCloseModal={handleCloseResetPasswordModal}
          setReloadPasswordEventsAPI={setReloadPasswordEventsAPI}
          reloadPasswordEventsAPI={reloadPasswordEventsAPI}
        />
      )}
      {resetPasswordHistoryModalOpen && (
        <ResetPasswordHistoryModal temporaryPasswordEvents={temporaryPasswordEvents} handleCloseModal={handleCloseResetPasswordHistoryModal} modalOpen={resetPasswordHistoryModalOpen} />
      )}
      {deActiveUser && (
        <InActiveUserModal openModal={deActiveUser} handleProceedFunction={handleActivateUser} handleCancelFunction={handleCloseInActiveModal} displayMessageContent={ErrorMessages?.inActiveUser} />
      )}
      {activeUser && <ReactiveUserModal active={activeUser} handleProceed={handleActivateUser} handleCancel={handleCancelActivateUser} displayContent={ErrorMessages?.activeUser} />}
      {loader && <Loader />}
    </div>
  );
}
