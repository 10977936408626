import { Modal } from "antd";
import CustomButtonBack from "../../component/CustomButtonBack";
import CustomButtonNext from "../../component/CustomButton";
import logoutIcon from "./../../../assets/images/icons/logoutIcon.png";
import ImageView from "../../component/ImageView";
import { useEffect, useState } from "react";
import PopupBlockModal from "../popup-block/PopupBlockModal";
import { useSelector } from "react-redux";

interface PopupBlockModalInterface {
  open: boolean;
  logout: () => void;
  isOpenLogout: (isOpen: boolean) => void;
  handleModalClose: () => void;
}
const LogoutModal = (props: PopupBlockModalInterface) => {
  const { handleModalClose, open, logout, isOpenLogout } = props;
  const [isActivePopup, setIsActivePopup] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const popUpBlockerStatus = useSelector((state: any) => state?.popUpBlocker?.popUpBlockerStatus);

  useEffect(() => {
    if (open) {
      setModalOpen(true);
      //This code is commented out because the popUpBlocker modal is disabled for some reason
      // if (popUpBlockerStatus) {
      //   setIsActivePopup(true);
      // } else {
      //   setModalOpen(true);
      // }
    }
  }, []);

  const handlePopupModalClose = () => {
    setIsActivePopup(false);
    setModalOpen(false);
    isOpenLogout(false);
  };
  const handleRequestApi = () => {
    logout();
  };
  return (
    <>
      <Modal className="p-10  show-notification  logoutModal rounded-lg" maskClosable={false} centered open={modalOpen} footer={false} onCancel={handleModalClose}>
        <div className="modal-body  pt-4 ">
          <div className="flex w-full justify-center items-center flex-col">
            <div className="w-20 h-20">
              <ImageView alt="notificationDone" src={logoutIcon} />
            </div>
            <h5 className=" font-Inter text-[24px] pt-3 font-semibold"> Logout</h5>
            <p className="text-[20px] text-center font-Inter font-medium text-[#475467]">Are you sure you want to logout?</p>
          </div>
        </div>
        <div className="modal-footer pt-9">
          <div className="w-full flex justify-center">
            <CustomButtonBack onClick={handleModalClose} text={"Cancel"} />
            <CustomButtonNext onClick={handleRequestApi} text={"Yes"} />
          </div>
        </div>
      </Modal>
      {isActivePopup && <PopupBlockModal open={isActivePopup} handleModalClose={handlePopupModalClose} />}
    </>
  );
};

export default LogoutModal;
