import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDropzone } from "react-dropzone";
import icon from "../../../../src/assets/images/icons/dragIcon.svg";
import { ErrorMessages } from "../../../const/Messages";
import { triggerNotification } from "../../../layouts/toast/ToastBar";
import useGetApiRequests from "../../../services/axios/useApiRequests";
import { useSelector } from "react-redux";
import { retrieveData } from "../../../services/storage/Storage";
const DeviceManagementDragAndDrop = () => {
  const navigate = useNavigate();
  const keycloak = useSelector((state: any) => state?.keycloak);
  const userId: string = keycloak?.KeycloakInfo?.subject || "";
  const realmId = retrieveData("realmId", true);
  const [isActiveUploadContent, setIsActiveUploadContent] = useState(true);
  const [errorMessages, setErrorMessages] = useState("");

  const [file, setFile] = useState<any>(null);
  const deviceManagementCSVImportApi = useGetApiRequests("deviceManagementCSVImport", "POST");
  const createPayload = (info: any, drag: boolean = false) => {
    if (info) {
      setFile(info);
      const formData = new FormData();
      formData.append("name", info.name);
      formData.append("file", info);
      requestUpload(formData);
    }
  };

  const requestUpload = async (formData: any) => {
    setIsActiveUploadContent(false);
    const payload = {
      userId: userId,
      realmId: realmId,
    };

    try {
      const headers = { "Content-Type": "multipart/form-data" };
      const response: any = await deviceManagementCSVImportApi(formData === "" ? {} : formData, { UserCsvRequestDtoStr: payload }, {}, headers);
      const status = response.status;
      if (status === 200) {
        const data = response?.data;
        setTimeout(() => {
          triggerNotification("success", "", data?.message, "topRight");
        }, 2000);
        setTimeout(() => {
          setIsActiveUploadContent(true);
          navigate("/device-management");
        }, 3000);
      }
    } catch (err: any) {
      setIsActiveUploadContent(true);
    }
  };

  const onDrop = (info: any) => {
    const { size, type } = info[0];
    const isCSV = type === "text/csv";
    const isLt20M = size / 1024 / 1024 < 0.1;

    if (!isCSV) {
      setErrorMessages(ErrorMessages?.uploadCsvFile);
      return;
    }
    if (!isLt20M) {
      setErrorMessages(ErrorMessages?.csvErrorMessageForDeviceManagement);
      return;
    }
    createPayload(info[0], false);
  };
  const { getRootProps, getInputProps } = useDropzone({ onDrop });
  return (
    <div>
      {" "}
      <div className="dragger">
        <div {...getRootProps()} className="dragAndDrop-container cursor-pointer text-center p-5 justify-center items-center flex">
          <input {...getInputProps()} />
          {isActiveUploadContent ? (
            <div>
              <div className="ant-upload-drag-icon w-full flex justify-center">
                <div>
                  <img src={icon} alt="icon" />
                </div>
              </div>
              <p className="ant-upload-text">
                <span>Click to Upload or </span> Drag and Drop
              </p>
              <p className="ant-upload-hint">CSV file format (max 2MB)</p>
              {errorMessages && <p className="error-message text-center left-[50%] translate-x-[-50%]">{errorMessages}</p>}
            </div>
          ) : (
            <div className="processingData">
              <p className="processingDataText">Processing your data...</p>
              <div className="processingDataFile-container flex items-center justify-center">
                <div className="processingDataFile flex items-center mt-2 gap-4">
                  <span className="material-symbols-outlined">hourglass_bottom</span> <span className="fileName">{file?.name}</span>
                </div>
              </div>
              <p className="processingDataHint mt-2">Please wait as this might take some time if your data is huge</p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default DeviceManagementDragAndDrop;
