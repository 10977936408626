import React, { useEffect, useState } from "react";
import useGetApiRequests from "../../../../../services/axios/useApiRequests";
import { handleRequestError } from "../../../../../layouts/toast/ErrorNotificationMessage";
import { retrieveData } from "../../../../../services/storage/Storage";
import { getListOfApps } from "../../user-dashboard/groups/modal/ListOfUserHelperFile";
import { triggerNotification } from "../../../../../layouts/toast/ToastBar";
import { useNavigate } from "react-router-dom";
import { getRolesList } from "../../user-dashboard/adduser/usertable/CustomTableUtils";
import DeviceTokenPhoneCheck from "../../../../../layouts/modal/DeviceTokenPhoneCheck/DeviceTokenPhoneCheck";
import { useDispatch } from "react-redux";
import { setUserManagementLoader } from "../../../../../redux/slice/UserManagementSlice";
import UserManagementForm from "./UserManagementForm";
import { setUserDetailDto } from "../../../../../redux/slice/user-basic-details/UserBasicDetailsSlice";

const BasicDetails = (props: any) => {
  const { setUserEnabled, setDisableToggle, enable, handleCloseLoader } = props;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const updateBasicDetails = useGetApiRequests("basicDetails", "PUT");
  const realmId = retrieveData("realmId", true);
  const userName = retrieveData("userName", true);
  const [listOfApps, setListOfApps] = useState([]);
  const { isEdit, selectedRowObject } = props;
  const currentSelectedRow = retrieveData("currentSelectedRow", true);
  const getAppList = useGetApiRequests("getListOfAppsByRealmId", "GET");
  const [isFormDirty, setIsFormDirty] = useState(false);
  const getUserInfo = useGetApiRequests("getUserProfile", "GET");
  const [loader, setLoader] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [userDetails, setUserDetails] = useState<any>([]);
  const [individualApps, setIndividualApps] = useState<any>([]);
  const [checkedState, setCheckedState] = useState<{ [key: string]: boolean }>({});
  const [isActiveDeviceRestriction, setIsActiveDeviceRestriction] = useState(false);
  const [tempListApps, setTempListApps] = useState<any>([]);
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [disableFields, setDisableFields] = useState<boolean>(false);
  const [isActiveDeviceToken, setIsActiveDeviceToken] = useState<boolean>(false);
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);
  const [submittedFormValue, setSubmittedFormValue] = useState(null);
  const [phoneNumber, setPhoneNumber] = useState<string>("");
  const [restrictionList, setRestrictionList] = useState<any>([]);
  const [removedDeviceRestrictions, setRemovedDeviceRestrictions] = useState<any>([]);
  const [selectedIds, setSelectedIds] = useState<any>(null);
  const [initialValues, setInitialValues] = useState<any>({
    firstName: "",
    lastName: "",
    workEmail: "",
    userType: "User",
    phone: "",
    personalEmail: "",
    customField: [{ value: "", name: "Untitled", id: "untitled" }],
    customField2: [{ value: "", name: "Untitled", id: "untitled" }],
  });
  const [roleSelected, setRoleSelected] = useState("User");
  const getRoleList = useGetApiRequests("getRolesList", "GET");
  const [userTypeList, setUserTypeList] = useState<any>([]);
  useEffect(() => {
    init();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [enable]);

  const init = () => {
    getRoles();
  };
  interface Attribute {
    name: string;
    value: string;
    custom: boolean;
  }

  const getRoles = async () => {
    setLoader(true);
    const roleList: any = await getRolesList(realmId, getRoleList);
    const user = { label: "User", value: "User" };
    roleList?.push(user);
    setUserTypeList(roleList);
    getUserInformation(roleList);
  };
  const getAppData = async (el: any) => {
    try {
      const individualApps = await getListOfApps(realmId, setLoader, getAppList);
      const filteredAppList = individualApps.filter((obj: any) => !el.some((item: any) => item?.value === obj?.value));
      setIndividualApps(filteredAppList);
    } catch (error) {
      handleRequestError(error);
      setLoader(false);
    }
  };
  const checkDeviceToken = (dataArray: any): boolean => {
    //Check if any item in the data array has a name of 'deviceToken'
    // eslint-disable-next-line array-callback-return
    return dataArray.some((item: any) => {
      if (item.name === "deviceToken") {
        setIsActiveDeviceToken(true);
      }
    });
  };

  const initUserInformation = (el: any) => {
    const newAttribute = el?.attributes;
    checkDeviceToken(newAttribute);
    const fieldArray = newAttribute.filter((item: any) => item?.custom);
    const userData: any = {};
    el?.attributes?.forEach((attribute: any) => {
      if (attribute.name === "personal_email_id" || attribute.name === "telephoneNumber") {
        userData[attribute.name] = attribute.value ?? "";
      }
    });
    setInitialValues({
      ...initialValues,
      firstName: el.firstName ?? "",
      lastName: el.lastName ?? "",
      workEmail: el.username ?? "",
      phone: userData.telephoneNumber ?? "",
      personalEmail: userData.personal_email_id ?? "",
      customField2: fieldArray.length === 0 ? [] : fieldArray,
    });
  };
  const handleToBack = () => {
    setModalOpen(true);
  };
  const getUserInformation = (roleList: any) => {
    const pathParams = {
      id: currentSelectedRow?.key,
    };
    getUserInfo("", {}, pathParams)
      .then((response: any) => {
        const data = response?.data?.data;
        const rolesInInitialValue = roleList.find((item: any) => item?.value === data?.userRoleList?.[0]?.id);
        if (rolesInInitialValue && Object.keys(rolesInInitialValue).length > 0) {
          setRoleSelected(rolesInInitialValue?.key);
        }
        if (data?.userDetailDto?.username === userName) {
          setDisableFields(true);
          setDisableToggle(true);
        }
        setUserDetails(data);
        initUserInformation(data?.userDetailDto);
        dispatch(setUserDetailDto(data?.userDetailDto));
        setUserEnabled(data?.userDetailDto?.enabled);
        setLoader(false);
        handleCloseLoader();
        if (data?.userDeviceInfoDtoList?.length > 0) {
          const filterDeviceBased = data?.userDeviceInfoDtoList?.filter((item: any) => item?.serialNumber);
          if (filterDeviceBased?.length > 0) {
            setIsActiveDeviceRestriction(true);
            setRestrictionList(filterDeviceBased);
          }
        }

        if (data.akkuClientGroupMapDto?.userClientGroupDtoListStringMap) {
          const initialState: { [key: string]: boolean } = {};
          data.akkuClientGroupMapDto?.userClientGroupDtoListStringMap &&
            Object.keys(data.akkuClientGroupMapDto?.userClientGroupDtoListStringMap).forEach((groupName) => {
              initialState[groupName] = true;
            });
          setCheckedState(initialState);
        }
        const updatedUserClientDtoList = data?.userClientDtoList.map((item: any) => {
          return {
            ...item,
            label: item.app,
            checked: true,
            value: item.akkuClientId,
          };
        });
        setTempListApps(updatedUserClientDtoList);
        setListOfApps(updatedUserClientDtoList);
        getAppData(updatedUserClientDtoList);
      })
      .catch((err: any) => {
        setLoader(false);
        handleRequestError(err);
      });
  };
  const onChange = (value: string, setFieldValue: any) => {
    setRoleSelected(value);
    setFieldValue("userType", value);
  };
  const onSubmit = (values: any) => {
    setSubmittedFormValue(values);
    if (phoneNumber === "active" && isActiveDeviceToken) {
      handleOpenConfirmationModal();
    } else {
      handlePayload(values, false);
    }
  };

  const handleOnChangePhone = (value: any, setFieldValue: any) => {
    setPhoneNumber("active");
    setFieldValue("phone", value);
  };

  const handleOpenConfirmationModal = () => {
    setOpenConfirmationModal(true);
  };
  const closeConfirmationModal = () => {
    setIsActiveDeviceToken(false);
    setOpenConfirmationModal(false);
    handlePayload(submittedFormValue, false);
  };

  const handleProceed = () => {
    setIsActiveDeviceToken(false);
    setOpenConfirmationModal(false);
    handlePayload(submittedFormValue, true);
  };

  const handelSplit = (arrayList: any, replacementMap: any) => {
    const result = Object.entries(replacementMap).map(([key, value]) => ({
      name: key,
      value: value,
      custom: true,
    }));
    const combinerArray = result.concat(arrayList);
    const uniqueData = combinerArray.reduce((acc: any, current: any) => {
      const x = acc.find((item: any) => item.name === current.name);
      if (!x) {
        return acc.concat([current]);
      } else {
        return acc;
      }
    }, []);
    return uniqueData;
  };

  const handleDeleteUserDetailsObject = (userClientGroupDtoList: any, userDetailsValues: any, falseKeys: any) => {
    userClientGroupDtoList &&
      Object.keys(userClientGroupDtoList).forEach((groupName) => {
        if (!falseKeys?.includes(groupName)) {
          delete userDetailsValues.akkuClientGroupMapDto.userClientGroupDtoListStringMap[groupName];
        }
      });
    return userDetailsValues;
  };

  const handlePayload = (formValues: any, deviceToken: boolean) => {
    try {
      let userDetailsValues = { ...userDetails };
      // Update userDetailDto properties
      userDetailsValues.userDetailDto = {
        ...userDetailsValues.userDetailDto,
        firstName: formValues.firstName,
        username: formValues.workEmail,
        lastName: formValues.lastName,
      };

      // Filter out attributes with name "personal_email_id" or "clientAppId"
      userDetailsValues.userDetailDto.attributes = userDetailsValues?.userDetailDto?.attributes?.filter((item: any) => item?.name !== "personal_email_id" && item?.name !== "clientAppId");

      const transformedList = listOfApps.map((item: any) => ({
        name: "clientAppId",
        value: `${item?.value}`,
      }));

      userDetailsValues.userDetailDto.attributes = userDetailsValues?.userDetailDto?.attributes
        .filter((item: Attribute) => {
          // Remove the object if the name is "telephoneNumber" and formValues?.phone is falsy ("" or undefined)
          if (item?.name === "telephoneNumber" && (!formValues?.phone || formValues?.phone === "")) {
            return false; // Remove this item
          }
          return true; // Keep all other items
        })
        .map((item: Attribute) => {
          // If it's "telephoneNumber" and formValues?.phone is defined and not an empty string, update the value
          if (item?.name === "telephoneNumber" && formValues?.phone) {
            return { ...item, value: formValues.phone };
          }
          return item; // Return the item as is for other attributes
        });

      // If "telephoneNumber" is not found and formValues?.phone exists, add it
      if (formValues?.phone) {
        const hasTelephoneNumber = userDetailsValues.userDetailDto.attributes.some((item: Attribute) => item.name === "telephoneNumber");

        if (!hasTelephoneNumber) {
          userDetailsValues.userDetailDto.attributes.push({
            name: "telephoneNumber",
            value: formValues?.phone,
          });
        }
      }

      const customFieldsValues = [...formValues.customField, ...formValues.customField2];
      const transformedCustomField = customFieldsValues?.map((item, index) => {
        if (item.hasOwnProperty("id")) {
          delete item.id;
          item.custom = true;
        }
        return item;
      });

      const replacementMap = transformedCustomField.reduce((acc: any, item: any) => {
        acc[item.name] = item.value;
        return acc;
      }, {});
      const attributes = userDetailsValues?.userDetailDto?.attributes;
      const customFields = attributes.filter((item: any) => !item.custom);
      const updatedArray: any = handelSplit(customFields, replacementMap);
      userDetailsValues.userDetailDto.attributes = [...updatedArray, ...transformedList, ...(formValues?.personalEmail ? [{ name: "personal_email_id", value: formValues.personalEmail }] : [])];
      let userClientGroupDtoList = userDetailsValues?.akkuClientGroupMapDto?.userClientGroupDtoListStringMap;
      const filteredData = userDetailsValues?.userDetailDto?.attributes?.filter((item: any) => item?.name !== "LDAP_ENTRY_DN" && item?.name !== "modifyTimestamp");
      userDetailsValues.userDetailDto.attributes = filteredData;

      // Update userDeviceInfoDto based on isActiveDeviceRestriction
      if (removedDeviceRestrictions?.length > 0) {
        userDetailsValues.userDeviceInfoDtoList = removedDeviceRestrictions;
      } else {
        delete userDetailsValues?.userDeviceInfoDtoList;
      }

      const falseKeys: any = [];

      Object.entries(checkedState).forEach(([key, value]) => {
        if (value === false) {
          falseKeys.push(key);
        }
      });

      if (falseKeys?.length > 0) {
        handleDeleteUserDetailsObject(userClientGroupDtoList, userDetailsValues, falseKeys);
      } else {
        userDetailsValues.akkuClientGroupMapDto.userClientGroupDtoListStringMap = null;
      }

      setLoader(true);

      if (roleSelected !== "User") {
        const idList = [];
        const roleId = {
          id: roleSelected,
        };
        idList.push(roleId);
        userDetailsValues.userRoleList = idList;
      } else {
        userDetailsValues.userRoleList = [];
      }

      const queryParams = {
        realmId: realmId,
      };

      if (deviceToken) {
        userDetailsValues.userDetailDto.attributes = userDetailsValues?.userDetailDto?.attributes?.filter((item: any) => item.name !== "deviceToken");
      }
      updateBasicDetails(userDetailsValues, queryParams)
        .then((response: any) => {
          handleSuccessMethod(response);
        })
        .catch((err: any) => {
          setLoader(false);
          handleCatchMethod(err);
        });
    } catch (err) {
      console.error("onSubmit function err:", err);
    }
  };
  const handleSuccessMethod = (response: any) => {
    if (response?.status === 201 || response?.status === 200) {
      navigate("/user");
      dispatch(setUserManagementLoader(true));
      triggerNotification("success", "", response?.data?.message, "topRight");
    }
  };
  const handleCatchMethod = (err: any) => {
    if (err?.response?.status === 401 || err?.response?.status === 500) {
      handleRequestError(err);
    } else {
      triggerNotification("error", "", err?.response?.data?.message, "topRight");
    }
  };
  const deleteRestriction = (elements: any) => {
    setSelectedIds(elements);
    setDeleteModal(true);
  };
  const handleModalClose = () => {
    setSelectedIds(null);
    setDeleteModal(false);
  };
  const confirmDeleteRestriction = () => {
    if (selectedIds !== null) {
      // Remove the selected IDs from the restriction list
      const updatedRestrictionList = restrictionList?.filter((item: any) => item?.akkuUserDeviceInfoId !== selectedIds?.akkuUserDeviceInfoId);
      setRestrictionList(updatedRestrictionList);
      setRemovedDeviceRestrictions([...removedDeviceRestrictions, selectedIds]);
    }
    handleModalClose();
  };

  return (
    <>
      <UserManagementForm
        props={{
          initialValues: initialValues,
          onSubmit: onSubmit,
          isFormDirty: isFormDirty,
          setFormDirty: setIsFormDirty,
          disableFields: disableFields,
          isEdit: isEdit,
          roleSelected: roleSelected,
          onChange: onChange,
          userTypeList: userTypeList,
          selectedRowObject: selectedRowObject,
          deleteRestriction: deleteRestriction,
          handleOnChangePhone: handleOnChangePhone,
          userDetails: userDetails,
          setListOfApps: setListOfApps,
          listOfApps: listOfApps,
          individualApps: individualApps,
          tempListApps: tempListApps,
          setCheckedState: setCheckedState,
          checkedState: checkedState,
          isActiveDeviceRestriction: isActiveDeviceRestriction,
          handleToBack: handleToBack,
          loader: loader,
          handleModalClose: handleModalClose,
          confirmDeleteRestriction: confirmDeleteRestriction,
          modalOpen: modalOpen,
          setModalOpen: setModalOpen,
          restrictionList: restrictionList,
          setUserDetails: setUserDetails,
          deleteModal: deleteModal,
        }}
      />
      <DeviceTokenPhoneCheck open={openConfirmationModal} handleCancel={closeConfirmationModal} handleProceed={handleProceed} />
    </>
  );
};
export default BasicDetails;
