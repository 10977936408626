import React, { useState } from "react";
import { Dropdown } from "antd";
import NewAppConfiguration from "../../modal/new-app-configure/NewAppConfiguration";
import { useDispatch, useSelector } from "react-redux";
import { hasPermission } from "../../../../../redux/slice/permissions/permissionUtils";
import { modulePermissions } from "../../../../../const/RolePermissions";
import DeleteProvisioningApp from "../../../provisioning/delete/DeleteProvisioningApp";
import { setProvisioningConfigEdit, setProvisioningEditStatus, setSSOConfigEdit, setSSOEditStatus } from "../../../../../redux/slice/app-store/appConfigureSlice";
import ProvisionEditStatusModal from "../modal/ProvisionEditStatusModal";
import { provisioningMessage } from "../../../const/appStoreConstMessage";
import PermissionsModal from "../../../../../layouts/permissionsModal/PermissionsModal";
import { cursorNotAllowedStyle } from "../../../../../hooks/hooks";
import { setConnectorId, setEditStatusToggle, setGeneralDetailsEdit, setIsDuplicate } from "../../../../../redux/slice/provisioning/ProvisioningSlice";
import { useNavigate } from "react-router-dom";
import { setAppConfigDetails } from "../../../../../redux/slice/app-store/AppDetailsSlice";

export default function CardOption({ item, setPermissionsModal, getProvisioningAppList }: any) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const appConfigure = useSelector((state: any) => state?.appConfigure);
  const permissions = useSelector((state: any) => state?.permissionsSlice?.attributes);

  const hasCreateAppStorePermission = permissions && hasPermission(permissions, modulePermissions.appStore, "create");
  const hasEditAppStorePermission = permissions && hasPermission(permissions, modulePermissions.appStore, "update");
  const hasDeleteAppStorePermission = permissions && hasPermission(permissions, modulePermissions.appStore, "delete");

  const [openPopUp, setOpenPopUp] = useState<boolean>(false);
  const [deleteAccess, setDeleteAccess] = useState<boolean>(false);
  const [provisionObject, setProvisionObject] = useState<object>({});
  const [isActiveDeleteModal, setIsActiveDeleteModal] = useState<boolean>(false);
  const [permissionModal, setPermissionModal] = useState(false);
  const [editAccess, setEditAccess] = useState<boolean>(false);
  const [message, setMessage] = useState<string>("");

  const handleDeleteProvisionOpenModal = (item: any) => {
    if (hasDeleteAppStorePermission) {
      const checkStatus = item?.akkuProvisioningConnectorDto?.isActive;
      if (checkStatus) {
        setDeleteAccess(true);
      } else {
        setIsActiveDeleteModal(true);
      }
    } else {
      setPermissionModal(true);
    }
  };
  const handleDeleteProvisionCloseModal = () => {
    setIsActiveDeleteModal(false);
  };

  const handleModalClose = () => {
    setOpenPopUp(false);
  };

  const handleRequestEdit = (item: any) => {
    const editConfig = {
      provisioningConfigEdit: !item.akkuProvisioningConnectorDto ? true : false,
      provisioningEditStatus: item?.akkuProvisioningConnectorDto?.isActive || false,
      SSOConfigEdit: item.akkuClientDto ? false : true,
      // SSOEditStatus: item?.akkuClientDto?.akkuCustomer?.active || false,
    };
    dispatch(
      setProvisioningConfigEdit({
        ...appConfigure.provisioningConfigEdit,
        isActiveConfig: editConfig?.provisioningConfigEdit,
      }),
    );
    // setSSOConfigEdit: (state, action: PayloadAction<any>) => {
    //   state.SSOConfigEdit = action.payload;
    // },
    dispatch(
      setSSOConfigEdit({
        ...appConfigure.SSOConfigEdit,
        isActiveConfig: editConfig?.SSOConfigEdit,
      }),
    );

    dispatch(setProvisioningEditStatus(editConfig?.provisioningEditStatus));
    // dispatch(setSSOEditStatus(editConfig?.SSOEditStatus));
    // provisioningEditStatus: item?.akkuProvisioningConnectorDto?.isActive || false,
    // SSOEditStatus: item?.akkuClientDto?.akkuCustomer?.active || false,

    //dispatch(clearAllEditData())
    setOpenPopUp(true);
    setProvisionObject(item);
  };

  const accessRequestEdit = (item: any): void => {
    if (hasEditAppStorePermission) {
      handleRequestEdit(item);
      dispatch(setAppConfigDetails(item?.akkuMasterClientDto));
    } else {
      setPermissionModal(true);
    }
  };

  const handleCloseEditAccessModal = () => {
    setEditAccess(false);
  };

  const handleRequestDuplicate = (item: any) => {
    if (hasCreateAppStorePermission) {
      if (item.akkuProvisioningConnectorDto) {
        // if (item?.akkuProvisioningConnectorDto?.isActive) {
        //   setEditAccess(true);
        //   setMessage(provisioningMessage?.requestDeactivateProvisioning);
        // } else {
        dispatch(setEditStatusToggle(true));
        dispatch(setConnectorId(item?.akkuProvisioningConnectorDto?.akkuProvisioningConnectorId));
        dispatch(setGeneralDetailsEdit(item?.akkuProvisioningConnectorDto));
        dispatch(setIsDuplicate(true));
        navigate("/account-provision");
        // }
      } else {
        setEditAccess(true);
        setMessage(provisioningMessage?.requestConfigureProvisioning);
      }
    } else {
      setPermissionModal(true);
    }
  };

  const items: any["items"] = [
    // {
    //   label: (
    //     <span
    //       role="none"
    //       onClick={() => handleRequestDuplicate(item)}
    //       className={`flex hover:bg-[#5441DA0F] justify-start items-center  px-3 py-1  ${cursorNotAllowedStyle(hasEditAppStorePermission)}`}
    //     >
    //       <span className="material-symbols-outlined mr-2">content_copy</span>
    //       <span className="text-[16px] font-medium">Duplicate</span>
    //     </span>
    //   ),
    //   key: "1",
    // },

    {
      label: (
        <span
          role="none"
          className={`flex justify-start items-center hover:bg-[#5441DA0F] px-3 py-1 ${cursorNotAllowedStyle(hasEditAppStorePermission)}`}
          onClick={() => {
            accessRequestEdit(item);
          }}
        >
          <span className="material-symbols-outlined mr-2">edit</span> <span className="text-[16px] font-normal font-Inter">Edit</span>
        </span>
      ),
      key: "2",
    },
    {
      label: (
        <span
          className={`px-3 hover:bg-[#5441DA0F] py-1 flex justify-start items-center ${cursorNotAllowedStyle(hasEditAppStorePermission)}`}
          role="none"
          onClick={() => handleDeleteProvisionOpenModal(item)}
        >
          <span className="material-symbols-outlined mr-2">delete</span> <span className="text-[16px] font-medium">Delete</span>
        </span>
      ),
      key: "3",
    },
  ];
  const handleCloseDeleteAccessModal = () => {
    setDeleteAccess(false);
  };
  const handleClosePermissionModal = () => {
    setPermissionModal(false);
  };
  return (
    <>
      <Dropdown menu={{ items }} trigger={["click"]} className="dropdown absolute right-0 top-1">
        <span className="mt-3 mr-2 material-symbols-outlined text-[#98A2B3] cursor-pointer self-start">more_vert</span>
      </Dropdown>

      <NewAppConfiguration
        openPopUp={openPopUp}
        handleModalClose={handleModalClose}
        hasEditAppStorePermission={hasEditAppStorePermission}
        setPermissionsModal={setPermissionsModal}
        provisionObject={provisionObject}
      />
      {permissionModal && <PermissionsModal open={permissionModal} close={handleClosePermissionModal} />}
      {isActiveDeleteModal && (
        <DeleteProvisioningApp
          handleClose={handleDeleteProvisionCloseModal}
          open={isActiveDeleteModal}
          connectorId={item?.akkuProvisioningConnectorDto?.akkuProvisioningConnectorId}
          getProvisioningAppList={getProvisioningAppList}
          items={item}
        />
      )}
      {editAccess && <ProvisionEditStatusModal open={editAccess} handleModalClose={handleCloseEditAccessModal} message={message} />}
      {deleteAccess && <ProvisionEditStatusModal open={deleteAccess} handleModalClose={handleCloseDeleteAccessModal} message={provisioningMessage?.requestDeactivateProvisioning} />}
    </>
  );
}
