import { combineReducers } from "redux";
import DashboardSlice from "./slice/DashboardSlice";
import DashboardFilterSlice from "./slice/DashboardFilterSlice";
import TableExpand from "./slice/TableExpand";
import AppSearchSlice from "./slice/AppSearchSlice";
import appsRenderSlice from "./slice/appsRenderSlice";
import LDAPSliceSlice from "./slice/LDAPSlice";
import AuthSlice from "./slice/AuthSlice";
import AnnualPlanToggle from "./slice/AnnualPlanToggle";
import FeatureListSlice from "./slice/FeatureListSlice";
import RegisterResponse from "./slice/RegisterResponse";
import OrgSliceReducer from "./slice/OrgSlice";
import AddUserSlice from "./slice/AddUserSlice";
import SingleUploadSlice from "./slice/SingleUploadSlice";
import keycloakSlice from "./slice/Users/KeyCloakSlice";
import MobileNumberReducer from "./slice/Users/MobileNumberSlice";
import UserDetailsReducer from "./slice/Users/UserDetailSlice";
import UserDashboardUpdateSlice from "./slice/Users/UserDashboardUpdateSlice";
import AppDetailsSlice from "./slice/app-store/AppDetailsSlice";
import DeviceRestrictionSlice from "./slice/DeviceRestrictionSlice";
import GroupSlice from "./slice/GroupSlice";
import CustomerSlice from "./slice/CustomerSlice";
import BindCredentialSlice from "./slice/edit-ldap/BindCredentialSlice";
import RolesAndPermissionSlice from "./slice/roles-permission/RolesAndPermissionSlice";
import themeReducer from "./slice/theme/themeSlice";
import restrictionReducer from "./slice/restriction/RestrictionSlice";
import permissionsSlice from "./slice/permissions/permissions";
import IpRestrictionSlice from "./slice/IPSlice";
import LdapInitDataSlice from "./slice/edit-ldap/LDAPInitialDataSlice";
import ProfileDetailsSlice from "./slice/profile/ProfileDetailsSlice";
import credentialsSlice from "./slice/credential-management/credentialsSlice";
import UserManagementSlice from "./slice/UserManagementSlice";
import provisioningSlice from "./slice/provisioning/ProvisioningSlice";
import appConfigureSlice from "./slice/app-store/appConfigureSlice";
import userBasicDetailsSlice from "./slice/user-basic-details/UserBasicDetailsSlice";
import deviceManagementSlice from "./slice/device-management/deviceManagementSlice";
import PopUpBlockerStatus from "./slice/PopUpBlockerStatus";
const rootReducer = combineReducers({
  auth: AuthSlice,
  dashboardDetails: DashboardSlice,
  dashboardFilterDetails: DashboardFilterSlice,
  TableExpand: TableExpand,
  appSearchSlice: AppSearchSlice,
  appsRenderSlice: appsRenderSlice,
  LDAPFormDetails: LDAPSliceSlice,
  AnnualPlanToggle: AnnualPlanToggle,
  FeatureListSlice: FeatureListSlice,
  RegisterResponse: RegisterResponse,
  OrgSliceReducer: OrgSliceReducer,
  addUserSlice: AddUserSlice,
  SingleUploadSlice: SingleUploadSlice,
  keycloak: keycloakSlice,
  forgotUsernameMobileNumber: MobileNumberReducer,
  userDetails: UserDetailsReducer,
  UserDashboardUpdateSlice: UserDashboardUpdateSlice,
  AppDetailsSlice: AppDetailsSlice,
  DeviceRestrictionSlice: DeviceRestrictionSlice,
  GroupSlice: GroupSlice,
  CustomerSlice: CustomerSlice,
  BindCredentialSlice: BindCredentialSlice,
  RolesAndPermissionSlice: RolesAndPermissionSlice,
  theme: themeReducer,
  restriction: restrictionReducer,
  permissionsSlice: permissionsSlice,
  IpRestrictionSlice: IpRestrictionSlice,
  LdapInitDataSlice: LdapInitDataSlice,
  ProfileDetailsSlice: ProfileDetailsSlice,
  credentialsSlice: credentialsSlice,
  userManagementSlice: UserManagementSlice,
  provisioning: provisioningSlice,
  appConfigure: appConfigureSlice,
  userBasicDetails: userBasicDetailsSlice,
  deviceManagement: deviceManagementSlice,
  popUpBlocker: PopUpBlockerStatus,
});
export default rootReducer;
